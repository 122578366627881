'use strict';

(function(){

 /**
  * @class
  *
  * USTTY variant of the ITA2 Teletypewriter Standard.
  *
  **/
  function USTTY(configs){
    configs.onWRU = 'WRU';
    ITA2.call(this, configs);

    this.figs[5]  = this.figs[11];
    this.figs[9]  = '$';
    this.figs[11] = '\'';
    this.figs[17] = '\"';
    this.figs[20] = '#';
    this.figs[30] = ';';
  }
  USTTY.prototype = Object.create(ITA2.prototype);
  USTTY.prototype.constructor = USTTY;

  // Export USTTY module
  window.USTTY = USTTY;

})();