'use strict';

(function(){

  var noop = function(){return '';};

 /**
  * @class
  * 
  * ITA2 Teletypewriter Standard. 
  * 
  * @param {object} configs - configurations
  * @param {function} configs.onBELL - callback to run on BELL keypress
  * @param {function} configs.onBLANK - " BLANK keypress
  * @param {function} configs.onCR    - " CR keypress
  * @param {function} configs.onFIGS  - " FIGS keypress
  * @param {function} configs.onLF    - " LF keypress
  * @param {function} configs.onLTRS  - " LTRS keypress
  * @param {function} configs.onSP    - " SP keypress
  * @param {function} configs.onWRU   - " WRU keypress
  * 
  * @prop {object} ltrs - array of letters
  * @prop {object} figs - array of figures
  * @prop {object} ctrls - mapping of characters to control functions
  *
  **/
  function ITA2(configs){

    var BELL = configs.onBELL || noop;
    var BLANK = configs.onBLANK || noop;
    var CR = configs.onCR || noop;
    var FIGS = configs.onFIGS || noop;
    var LF = configs.onLF || noop;
    var LTRS = configs.onLTRS || noop;
    var SP = configs.onSP || noop;
    var WRU = configs.onWRU || noop;

    if (!(FIGS && LTRS && LF && SP && CR && BLANK && BELL && WRU)){
      throw 'Handler not set for one or more control keys';
    }

    this.ltrs = [
      // 00-09
      BLANK,
      'E',
      LF,
      'A',
      SP,
      'S',
      'I',
      'U',
      CR,
      'D',
      // 10-19
      'R',
      'J',
      'N',
      'F',
      'C',
      'K',
      'T',
      'Z',
      'L',
      'W',
      // 20-29
      'H',
      'Y',
      'P',
      'Q',
      'O',
      'B',
      'G',
      FIGS,
      'M',
      'X',
      // 30-31
      'V',
      LTRS
    ];
    this.figs = [
      // 00-09
      BLANK,
      '3',
      LF,
      '-',
      SP,
      '\'',
      '8',
      '7',
      CR,
      WRU,
      // 10-19
      '4',
      BELL,
      ',',
      '!',
      ':',
      '(',
      '5',
      '+',
      ')',
      '2',
      // 20-29
      '£',
      '6',
      '0',
      '1',
      '9',
      '?',
      '&',
      FIGS,
      '.',
      '/',
      // 30-31
      '=',
      LTRS
    ];
    this.ctrls = {
      '\n' : [CR, LF, LTRS, LTRS],
      ''   : [BLANK],
      ' '  : [SP]
    };
  }

 /**
  * @function
  * 
  * Looks up the character key for the given index value and shift.
  *  If the key is a simple character, return it. If the key is a
  *  control key, execute the corresponding function and return 
  *  the result. For example, `keyLookup(false, 1)` will return the 
  *  2nd item in the LTRS table ('E'); `keyLookup(true, 11)` 
  *  will return the 12th item in the FIGS table (since the 12th 
  *  item in the FIGS table is the BELL control function, the 
  *  `keyLookup()` will execute the BELL control function and return
  *  the result).
  * 
  * @param {Boolean} shift - use LTRS table if false, otherwise use
  *                          the FIGS table
  * @param {number} index - index of key to look up
  * 
  * @throws if `index` is out of bounds
  * @returns {string} - the key
  **/

  ITA2.prototype.keyLookup = function(shift, index){
    if (typeof index !== 'number' || index > 31 || index < 0){
      throw 'index must be a number between 0 and 31, but got ' + index;
    }
    var f = shift ? this.figs[index] : this.ltrs[index];
    return typeof f === 'function' ? f() : f;
  };

 /**
  * @function
  * 
  * Looks up the numerical key code key(s) for the given character
  *  and shift value. This function iterates through the LTRS and 
  *  FIGS table looking for the given character. If the character
  *  is found, the function will return an array containing a 
  *  LTRS or FIGS key code (if appropriate) followed by the character's
  *  ITA2 key code. For example, `codesLookup(false, '1')` will 
  *  return `[27, 23]` - the key codes for FIGS followed by the index
  *  of the '1' character in the FIGS table. `codesLookup(true, '1')`,
  *  however, will return only `[23]`, since the given shift value 
  *  indicates that the FIGS table is already selected.
  * 
  * @param {Boolean} shift - is the currently selected table LTRS or
  *                          FIGS
  * @param {string} key - one or more characters to convert
  * 
  * @returns {object} - an array of numerical key codes
  **/
  ITA2.prototype.codesLookup = function(shift, key){
    if (typeof key !== 'string') {
      throw 'typeof key must be string but got ' + typeof key;
    }

    var codes = [];
    var expanded = this.ctrls[key] || [key];

    for (var i = 0; i < expanded.length; i++){
      var s = expanded[i];
      for (var j = 0; j < 32; j++){
        if (this.ltrs[j] === s){
          if (shift){
            codes.push(31);
            shift = false;
          }
          codes.push(j);
        } else if (this.figs[j] === s){
          if (!shift) { 
            codes.push(27);
            shift = true; 
          }
          codes.push(j);
        }
      }
    }
    if (codes.length < 1) {
      throw '\'' + key + '\' is not a valid character or control';
    }
    return codes;
  };

  // Export TeletypeModel14 module
  window.ITA2 = ITA2;

})();