'use strict';

(function(){

  var applyConfigs;
  var base64ToUSTTYCodes;
  var baseName;
  var binaryDataToImpulseString;
  var binaryDataToUSTTYCodes;
  var defaults;
  var impulseStringToBase64;
  var impulseStringToBinaryData;
  var impulseStringToUSTTYCodes;
  var libName;
  var usttyCodeToImpulseString;

  libName = 'Corvina';
  baseName = libName.toLowerCase;

  defaults = {
    format: 'data'
  };

 /**
  * @function
  * 
  * Merges default configuration options with user-specified
  *  configurations.
  *
  * @param {object} config - dictionary of configuration options
  * @returns {object} - dictionary of configuration options merged
  *                     with default configurations
  *
  **/
  applyConfigs = function(config){
    var d;

    config = config || {};
    d = {};
    for (var k in defaults) {
      var v = defaults[k];
      if (k in config) {
        d[k] = config[k];
      } else {
        d[k] = v;
      }
    }
    return d;
  };

 /**
  * @function
  * 
  * Converts binary encoded in base64 into an array of USTTY codes
  *   (e.g. 31 for LTRS).
  *
  * @param {string} data - base64 binary data
  * @returns {number[]} - an array of USTTY codes
  *
  **/
  base64ToUSTTYCodes = function(base64){
    var bytes;
    var len;
    var str;


    str =  window.atob(base64);
    len = str.length;
    bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++)        {
        bytes[i] = str.charCodeAt(i);
    }
    return binaryDataToUSTTYCodes(bytes.buffer);
  };

 /**
  * @function
  * 
  * Converts binary data into a String consisting of '1's and 
  *  '0's. Trailing '0's are removed.
  *
  * @param {object} data - an ArrayBuffer containing binary data.
  * @returns {string} - a String representation of an impulse stream
  *                     of spacing and marking pulses, where marking
  *                     pulses are represented by '1's and spacing
  *                     pulses are represented by '0's.
  *
  **/
  binaryDataToImpulseString = function(data){
    var bin; 
    var str;

    str = '';
    data = new Uint8Array(data);
    for (var i = 0; i < data.length; i++){
      bin = data[i].toString(2);
      str += '00000000'.substr(bin.length) + bin;
    }
    return str.replace(/0*$/g, '');
  };


 /**
  * @function
  * 
  * Converts an ArrayBuffer of binary data into USTTY character and
  *   control codes (e.g. 31 for LTRS).
  *
  * @param {string} data - ArrayBuffer of raw binary data
  * @returns {number[]} - an array of USTTY codes
  *
  **/
  binaryDataToUSTTYCodes = function(data){
    return impulseStringToUSTTYCodes(binaryDataToImpulseString(data));
  };

 /**
  * @function
  * 
  * Converts a String representation of an impulse stream into binary
  *  data formatted as a base64 String.
  *
  * @param {string} str - String representatation of an impulse stream
  *   of spacing and marking pulses, where marking pulses are 
  *   represented by '1's and spacing pulses are represented by '0's.
  * @returns {string} - String of binary data encoded in base64
  *
  **/
  impulseStringToBase64 = function(str){
    var binary;
    var bytes;
    var data;
    var len;

    binary = '';
    data = impulseStringToBinaryData(str);
    bytes = new Uint8Array(data);
    len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

 /**
  * @function
  * 
  * Converts a String representation of an impulse stream into binary
  *  data. 
  *
  * @param {string} str - String representatation of an impulse stream
  *                       of spacing and marking pulses, where marking
  *                       pulses are represented by '1's and spacing
  *                       pulses are represented by '0's.
  * @returns {object} - ArrayBuffer of binary data representing an 
  *                     impulse stream
  *
  **/
  impulseStringToBinaryData = function(str){
    var buff;
    var bytes; 
    var data; 
    var i;
    var padding;
    var septets;

    // break impulse string into septets
    septets = str.match(/0.{5}1/g);

    // append BLANK septets until the number of 
    // of septets is a multiple of 8
    padding = (8 - (septets.length % 8)) % 8;
    for (i = 0; i < padding; i++){
      septets.push('0000001');
    }

    // join the septets, then split into octets (bytes)
    str = septets.join('');
    bytes = str.match(/.{1,8}/g);

    // create and fill an array buffer with byte values
    buff = new ArrayBuffer(bytes.length);
    data = new Uint8Array(buff);
    for (i = 0; i < bytes.length; i++){
      data[i] = parseInt(bytes[i], 2);
    }

    return buff;
  };

 /**
  * @function
  * 
  * Converts a String representation of an impulse stream an array of 
  *  USTTY codes (e.g. 31 for LTRS, 1 for 'E', etc.)
  *
  * @param {string} str - String representatation of an impulse stream
  *                       of spacing and marking pulses, where marking
  *                       pulses are represented by '1's and spacing
  *                       pulses are represented by '0's.
  * @returns {object} - Array of USTTY codes (e.g. 31 for LTRS, 1 for 
  *                    'E', etc.)
  *
  **/  
  impulseStringToUSTTYCodes = function(str){
    var codes;
    var septets;

    codes = [];
    septets = str.match(/0.{5}1/g);
    for (var i = 0; i < septets.length; i++){
      septets[i] = septets[i].split('').reverse().join('').substring(1,6);
      codes[i] = parseInt(septets[i], 2);
    }
    return codes;
  };

 /**
  * @function
  * 
  * Converts an array of USTTY codes (e.g. 31 for LTRS, 1 for 'E', 
  *  etc.) into a String representation of an impulse stream of spacing
  *  and marking pulses, where marking pulses are represented by '1's 
  *  and spacing pulses are represented by '0's.
  *
  * @param {object} str - Array of USTTY codes (e.g. 31 for LTRS, 27 for 
  *                      FIGS, etc.)
  * @returns {string} - a String representation of an impulse stream
  *                     of spacing and marking pulses, where marking
  *                     pulses are represented by '1's and spacing
  *                     pulses are represented by '0's.
  *
  **/  
  usttyCodeToImpulseString = function(n){
    var bin;
    bin = n.toString(2);
    bin = '00000'.substr(bin.length) + bin;
    bin = bin.split('').reverse().join('');
    return '0' + bin + '1';
  };

 /**
  * @class
  * 
  * TeletypeModel14 simulates a Model 14 Teletypewriter configured
  *  with USTTY.
  *
  * @param {object} [config] - configuration options
  * @param {string} [config.format] - default USTTY encoding format. 
  *   Can be 'data', 'string', 'base64', or 'codes'
  *
  * @prop {object} _config - Teletype machine configuration
  * @prop {string} _config.format - default USTTY encoding format for
  *   encoding and decoding. Can be 'data', 'string', 'base64', or 
  *   'codes'.
  * @prop {boolean} shift - true if FIGS mode is selected, false if 
  *   LTRS mode is selected
  * @prop {object} ustty - USTTY instance responsible for USTTY code
  *   translations
  **/
  function TeletypeModel14(config){
    var BELL;
    var BLANK;
    var CR;
    var FIGS;
    var LF;
    var LTRS;
    var self;
    var SP;
    
    self = this;

    // Initialize configs
    this._config = applyConfigs(config);

    // Shift defaults to false (LTRS mode by default)
    this.shift = false;


    // USTTY Control Functions
    BELL = function(){
      return '';
    };

    BLANK = function(){
      return '';
    };

    CR = BLANK;

    FIGS = function(){
      self.shift = true;
      return '';
    };

    LF = function(){
      return '=';
    };

    LTRS = function(){
      self.shift = false;
      return '';
    };

    SP = function(){
      return ' ';
    };

    // Initialize USTTY 
    this.ustty = new USTTY({
      onBELL: BELL,
      onBLANK: BLANK,
      onCR: CR,
      onFIGS: FIGS,
      onLF: LF,
      onLTRS: LTRS,
      onSP: SP
    });

  }

 /**
  * @function
  * 
  * Decodes USTTY-encoded input into plaintext. Input may be formatted
  *  as an array of numeric USTTY codes (e.g. 31 for LTRS, 27 for FIGS),
  *  as a String representation of an impulse stream (e.g. 
  *  "01111110110111" for LTRS FIGS), or as binary representation of 
  *  an impulse stream where individual bits represent spacing and 
  *  marking pulses.
  *
  * @param {string | object} input - USTTY-encoded input to be decoded.
  *   May be an array of numeric USTTY codes or a String / binary 
  *   representation of an impulse stream.
  * @param {object} [options] - dictionary of options
  * @param {string} [options.format] - input format. May be 'codes',
  *   'string', 'base64', or 'data'. 
  * @returns {string} - decoded plaintext
  *
  **/  
  TeletypeModel14.prototype.decode = function(input, options){
    var format;
    var codes;
    var output;

    options = options || {};
    format = options.format || this._config.format;
    output = '';

    switch(format){
      case 'data':
        codes = binaryDataToUSTTYCodes(input);
        break;
      case 'string':
        codes = impulseStringToUSTTYCodes(input);
        break;
      case 'codes':
        codes = input;
        break;
      case 'base64':
        codes = base64ToUSTTYCodes(input);
        break;
      default:
        throw libName + ': decode format `' + format + '` not recognized';
    }
    for (var i = 0; i < codes.length; i++){
      try {
        output += this.ustty.keyLookup(this.shift, codes[i]);
      } catch(e) {
        throw libName + ': `' + codes[i] + 
          '` is not a valid USTTY code.';
      }
    }

    return output;
  };

 /**
  * @function
  * 
  * Encodes input text into USTTY. Output may be formatted as an array
  *  of numeric USTTY codes (e.g. 31 for LTRS, 27 for FIGS), as a String
  *  representation of an impulse stream (e.g. "01111110110111" for 
  *  LTRS FIGS), or as binary representation of an impulse stream where 
  *  individual bits represent spacing and marking pulses.
  *
  * @param {string} input - text to be encoded. All characters must be
  *   supported by USTTY.
  * @param {object} [options] - dictionary of options
  * @param {string} [options.format] - output format. May be 'codes',
  *   'string', 'base64', or 'data'. 
  * @returns {String | Object} - encoded plaintext. May be an array of
  *   numeric USTTY codes or a String / binary representation of an 
  *   impulse stream.
  *
  **/  
  TeletypeModel14.prototype.encode = function(input, options){
    var format;
    var ints;
    var str;

    options = options || {};
    format = options.format || this._config.format;

    ints = [];
    str = '';

    for (var i = 0; i < input.length; i++){
      var codes;
      try {
        codes = this.ustty.codesLookup(this.shift, input[i]);
      } catch(e){
        throw libName + ': character `' + 
          input[i] + '` is not compatible with USTTY';
      }
      for (var j = 0; j < codes.length; j++){
        this.shift = 
          (codes[j] === 27 && !this.shift) || (codes[j] === 31 && this.shift) ? 
          !this.shift : 
          this.shift;
        ints.push(codes[j]);
        str += usttyCodeToImpulseString(codes[j]);
      }     
    }

    switch(format){
      case 'codes':
        return ints;
      case 'string':
        return str;
      case 'data':
        return impulseStringToBinaryData(str);
      case 'base64':
        return impulseStringToBase64(str);
      default:
        throw libName + ': encode format ' + format + ' not recognized.';
    }
  };

  // Export TeletypeModel14 module
  window.TeletypeModel14 = TeletypeModel14;

})();